import React from "react";
import "./StudioVento.css";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Col, Row, Stack } from "react-bootstrap";
import AnimatedRoute from './../../AnimatedRoute/AnimatedRoute';
import { useImages } from "../../../ImageProvider/ImageProvider";

function StudioVento() {
  const { t } = useTranslation();
  const images = useImages();

  return (
    <AnimatedRoute>
      <Container className="d-flex justify-content-around pt-5">
        <div className="w-75">
          <h1 className="d-flex align-items-center justify-content-center my-5">
            {t("StudioVento.title")}
          </h1>
          <Stack gap={5} className="align-items-center">
            <Row className="d-flex size-row rounded">
              <Col xs={12} lg={6} className="d-flex justify-content-center">
                <div
                  className="position-relative size-image"
                  >
                  <img
                    src={images.studio_vento}
                    className="position-absolute object-fit-cover rounded w-100 h-100"
                    alt="imagen Quienes Somos"
                    />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="d-flex justify-content-center pt-md-4 container-text">
                  <p dangerouslySetInnerHTML={{ __html: t("StudioVento.text") }} className="texto"></p>
                </div>
              </Col>
            </Row>
          </Stack>
        </div>
      </Container>
    </AnimatedRoute>
  );
}

export default StudioVento;
