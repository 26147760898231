import React from "react";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import AnimatedRoute from "./../../AnimatedRoute/AnimatedRoute";
import "./Mercado.css";
import { useImages } from "../../../ImageProvider/ImageProvider";

function Mercado() {
  const { t } = useTranslation();
  const images = useImages();

  return (
    <AnimatedRoute>
      <Container className="d-flex justify-content-center">
        <div>
          <h1 className="d-flex align-items-center justify-content-center  mt-5 pt-5">
            {t("Market.title")}
          </h1>
          <div className="position-relative container-img">
            <img
              src={images.mercado_mapa}
              className=" img-map object-fit-cover rounded mt-4 w-100 h-100 position-img"
              alt="imagen Quienes Somos"
            />
          </div>
          <div className="mt-5 d-flex justify-content-center">
            <div className="container-countries p-3 mb-5">
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.China")} </span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.USA")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Spain")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Panama")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Colombia")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Venezuela")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Ecuador")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Peru")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Chile")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Brazil")}</span>
              <span><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" style={{ fill: "#ff9451" }}><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> {t("Market.Argentina")}</span>
            </div>
          </div>
        </div>
      </Container>
    </AnimatedRoute>
  );
}

export default Mercado;
