import './Carousel.css';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useImages } from '../../ImageProvider/ImageProvider';

function CarouselComponent() {

  const images = useImages();

  return (
      <Carousel data-bs-theme="light" className='h-100 w-100'>
        <Carousel.Item interval={5000} className='img-container'>
          <img src={images.carousel_1} alt='Foto' />
        </Carousel.Item>
        
        <Carousel.Item interval={5000} className='img-container'>
          <img src={images.carousel_2} alt='Foto' />
        </Carousel.Item>
        
        <Carousel.Item interval={5000} className='img-container'>
          <img src={images.carousel_3} alt='Foto' />
        </Carousel.Item>        
      </Carousel>
  );
}

export default CarouselComponent;