import './Coleccion.css';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import CardCollection from '../../Cards/CardCollection';
import AnimatedRoute from './../../AnimatedRoute/AnimatedRoute';
import { useImages } from '../../../ImageProvider/ImageProvider';

function Coleccion() {
	const { t } = useTranslation();
  const images = useImages();

	return (
		<AnimatedRoute>
			<Container className="d-flex align-items-center flex-column pt-5">
				<h1
					id="title"
					className="d-flex align-items-center justify-content-center my-5"
				>
					{t('collection.title')}
				</h1>

				<div className="px-4 mx-4 mb-4">
					<p>{t('collection.description')}</p>
				</div>

				<div className="pb-5 container-cards">
						<Row>
							<Col xs={12} md={6} lg={4} className="col">
								<CardCollection
									image={images.coleccion_lisas}
									subtitle={t('collection.subtitle1')}
									text={t('collection.text1')}
								/>
							</Col>

							<Col xs={12} md={6} lg={4} className="col card-2">
								<CardCollection
                  image={images.coleccion_estampados}
                  subtitle={t('collection.subtitle2')}
									text={t('collection.text2')}
								/>
							</Col>

							<Col xs={12} md={6} lg={4} className="col card-3">
								<CardCollection
									subtitle={t('collection.subtitle3')}
									image={images.coleccion_fantasia}
									text={t('collection.text3')}
								/>
							</Col>
						</Row>
				</div>
			</Container>
		</AnimatedRoute>
	);
}

export default Coleccion;
