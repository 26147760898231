import i18n from 'i18next';
import en from './assets/language/en.json'
import es from './assets/language/es.json'

const i18Instance = i18n.createInstance();

i18Instance
  .init({
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en
      },
      es: {
        translation: es
      }
    }
  });

export default i18Instance;