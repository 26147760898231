import "./Navbar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ukFlag from "./../../assets/language/uk_flag.png";
import esFlag from "./../../assets/language/spain_flag.png";
import { useMemo, useState } from "react";

export default function NavbarComponent() {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const setNavExpanded = (state) => {
    setExpanded(state);
  };

  const closeNav = () => {
    setExpanded(false);
  };

  const toggleLanguage = () => {
    if (i18n.language === "es") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  };

  const languageImage = useMemo(() => {
    if (i18n.language === "es") {
      return esFlag;
    } else {
      return ukFlag;
    }
  }, [i18n.language]);

  return (
    <div id="navbar">
      <Navbar
        expanded={expanded}
        onToggle={setNavExpanded}
        expand="lg"
        className="bg-light position-fixed z-3 w-100"
        collapseOnSelect={true}
      >
        <Container>
          <div className="NavResponsive d-flex">
            <Navbar.Brand as={Link} to="/" className="m-0">
              <div className="d-flex flex-column align-items-center">
                <h1 className="fs-6 mb-0">VENTO Textile</h1>
                <h1 className="fs-6 mb-0">- Est. 2019 -</h1>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
          </div>
          <div className="d-flex justify-content-xl-end">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  onClick={closeNav}
                  active={location.pathname === "/"}
                  as={Link}
                  to="/"
                >
                  {t("home.title")}
                </Nav.Link>
                <Nav.Link
                  onClick={closeNav}
                  active={location.pathname === "/quienes-somos"}
                  as={Link}
                  to="/quienes-somos"
                >
                  {t("aboutUs.title")}
                </Nav.Link>
                <Nav.Link
                  onClick={closeNav}
                  active={location.pathname === "/coleccion"}
                  as={Link}
                  to="/coleccion"
                >
                  {t("collection.title")}
                </Nav.Link>
                <Nav.Link
                  onClick={closeNav}
                  active={location.pathname === "/studio-vento"}
                  as={Link}
                  to="/studio-vento"
                >
                  {t("StudioVento.title")}
                </Nav.Link>
                <Nav.Link
                  onClick={closeNav}
                  active={location.pathname === "/mercado"}
                  as={Link}
                  to="/mercado"
                >
                  {t("Market.title")}
                </Nav.Link>
                <Nav.Link
                  onClick={closeNav}
                  active={location.pathname === "/contacto"}
                  as={Link}
                  to="/contacto"
                >
                  {t("Contact.title")}
                </Nav.Link>
                <Nav.Link onClick={toggleLanguage}>
                  <img width={22} src={languageImage} alt="" />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}
