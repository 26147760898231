import NavbarComponent from "../Navbar/Navbar";
import { I18nextProvider } from 'react-i18next';
import i18n from './../../i18n';
import './Layout.css';
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";

export default function Layout({children}){

  const location = useLocation();

    return(
      <I18nextProvider i18n={i18n}>
        <NavbarComponent/>
        <div id='Layout' className={location.pathname !== "/" ? "" : "d-flex flex-column"}>
          <div className={location.pathname !== "/" ? "min-vh-100" : "h-100 flex-grow-1"}>
            {children}
          </div>
          <Footer/>
        </div>
      </I18nextProvider>
    )
}