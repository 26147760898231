import React, { createContext, useContext, useEffect, useState } from "react";

const ImagesContext = createContext({});

function ImageProvider({ children }){
  const [images, setImages] = useState({});
  
  useEffect(()=>{
    fetch('./images/imagenes.json')
      .then(res => res.json())
      .then((res)=>{
        const rutaBase = "./images/";

        let fulPathImages = {};

        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            const rutaOriginal = res[key];
            const rutaActualizada = rutaBase + rutaOriginal;
            fulPathImages[key] = rutaActualizada;
          }
        }

        setImages(fulPathImages)
      })
  }, [])

  return <ImagesContext.Provider value={images}>{children}</ImagesContext.Provider>
};

export const useImages = () => useContext(ImagesContext);

export default ImageProvider;