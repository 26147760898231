import { motion } from "framer-motion";

const animations = {
  initial: { opacity: 0, scale: .95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: .95 }
}

export default function AnimatedRoute({children}){
    return(
      <motion.div className="h-100" variants={animations} initial="initial" animate="animate" exit="exit" transition={{duration: .5}} >
        {children}
      </motion.div>
    )
}