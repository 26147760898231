import React from "react";
import "./CardImage.css";

function CardImage({image }) {
  return (
    <div className="w-100 h-100">
          <img
            className="w-100 h-100 object-fit-cover image-position rounded"
            src={image}
            alt=""
          ></img>
    </div>
  );
}

export default CardImage;
