import './App.css';
import Coleccion from './components/Pages/Coleccion/Coleccion';
import Home from './components/Pages/Home/Home';
import {Route, Routes} from 'react-router-dom';
import QuienesSomos from './components/Pages/QuienesSomos/QuienesSomos';
import StudioVento from './components/Pages/StudioVento/StudioVento';
import Mercado from './components/Pages/Mercado/Mercado';
import Contacto from './components/Pages/Contacto/Contacto';
import Layout from './components/Layout/Layout';
import ImageProvider from './ImageProvider/ImageProvider';

function App() {
  return (
    <ImageProvider>
      <Layout>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/quienes-Somos' element={<QuienesSomos/>}/>
          <Route path='/coleccion' element={<Coleccion/>}/>
          <Route path='/studio-vento' element={<StudioVento/>}/>
          <Route path='/mercado' element={<Mercado/>}/>
          <Route path='/contacto' element={<Contacto/>}/>
        </Routes>
      </Layout>
    </ImageProvider>
  );
}

export default App;
