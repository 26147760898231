import React from "react";
import './CardCollection.css';

import { useTranslation } from 'react-i18next';

function CardCollection({image,subtitle,text}) {

  
	const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center p-3">
      <div className="container-card rounded">
        <div className="container-img-collection position-relative">
          <img src={image} alt="" className="position-absolute object-fit-cover rounded w-100 h-100 bottom-50 " ></img>
        </div>
        <div className="Container-text">
            <h1>{subtitle}</h1>
            <p dangerouslySetInnerHTML={{ __html: t(text) }} ></p>
          </div>
      </div>
    </div>
  );
}

export default CardCollection;
