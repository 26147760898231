import React from 'react';
import './QuienesSomos.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import AnimatedRoute from './../../AnimatedRoute/AnimatedRoute';
import { useImages } from '../../../ImageProvider/ImageProvider';

function QuienesSomos() {
	const { t } = useTranslation();
  const images = useImages();

	return (
		<AnimatedRoute>
			<Container className="d-flex align-items-center flex-column pt-5">
				<h1 className="d-flex align-items-center justify-content-center mt-5">
					{t('aboutUs.title')}
				</h1>
				<Row className="mt-5 flex-md-row justify-content-center">
					<Col xs={12} md={6} lg={5}
						className="d-flex justify-content-center blur py-4  rounded-img"
					>
						<div className="image-container position-relative">
							<img
								src={images.quienes_somos}
								className=" position-absolute w-100 h-100 rounded object-fit-cover image-Quienes-somos"
								alt="imagen Quienes Somos"
							/>
						</div>
					</Col>
					<Col xs={12} md={6} lg={5} 
						className="blur py-4 rounded-text text d-flex justify-content-center"
					>
						<p dangerouslySetInnerHTML={{ __html: t('aboutUs.text') }}></p>
					</Col>
				</Row>
			</Container>
		</AnimatedRoute>
	);
}

export default QuienesSomos;
